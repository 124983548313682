import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export class HealthModel{
    public serviceName:string;
    public status:string;
    public reason:string;
    constructor(serviceName:string,status:string,reason:string){
        this.serviceName=serviceName;
        this.status=status;
        this.reason=reason;
        
    }

}