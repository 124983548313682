import { Injectable ,Inject} from '@angular/core';
import { Response } from '@angular/http';
import { ManagementService } from './management.service';



@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(@Inject(ManagementService) private management:ManagementService ) { }
   
    public fetchApplicationHealth(url:string,callback:Function) {

      // Call Fetch User Profile Service API
     return this.management.doGet(url, (err: any, res: Response) => {      
          if(err) { 
            console.log(err)  
            callback(err,res); return; 
          };
          // Return success
          callback(null,res);
      });
      
  }
  
}
