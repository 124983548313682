import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HealthComponent } from './health/health.component';
// import {SuiModule} from 'ng2-semantic-ui';
import { HealthService } from './health.service';
import { ManagementService } from './management.service';
import {HttpModule} from '@angular/http';
import { AutoRefreshComponent } from './auto-refresh/auto-refresh.component';
import { LoginComponent } from './login/login.component'
import { Environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import {
 
  OktaAuthModule,
} from '@okta/okta-angular';
import { CommerceComponent } from './commerce/commerce.component';
import { PlusComponent } from './plus/plus.component';
import {SuiModule} from 'ng2-semantic-ui';
import { DashCardComponent } from './dash-card/dash-card.component';
import { DashButtonsComponent } from './dash-buttons/dash-buttons.component';
import { TranslatePipe } from './translate.pipe';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';




@NgModule({

  declarations: [
    AppComponent,
    HealthComponent,
    AutoRefreshComponent,
    LoginComponent,
    CommerceComponent,
    PlusComponent,
    DashCardComponent,
    DashButtonsComponent,
    TranslatePipe,
    HomeComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    SuiModule,
    OktaAuthModule.initAuth(Environment.getOktaConfig()),
    HttpClientModule
  
  ],
  providers: [HealthService,
    ManagementService,
    Environment,
    AuthService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
