import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthComponent } from './health/health.component';
import { AutoRefreshComponent } from './auto-refresh/auto-refresh.component';
import { LoginComponent } from './login/login.component';
import {OktaCallbackComponent,OktaLoginRedirectComponent,OktaAuthGuard} from '@okta/okta-angular/';
import { CommerceComponent } from './commerce/commerce.component';
import { PlusComponent } from './plus/plus.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  {path:'',pathMatch: 'full',redirectTo: 'core-sre'},
  {path:'home',component:HomeComponent,canActivate: [OktaAuthGuard]},
  {path:'core-sre',component:HealthComponent,canActivate: [OktaAuthGuard]},
  {path:'commerce',component:CommerceComponent,canActivate: [OktaAuthGuard]},
  {path:'plus',component:PlusComponent,canActivate: [OktaAuthGuard]},

  
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'login', component: OktaLoginRedirectComponent },
  { path: '**', redirectTo: 'login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
